<template>
  <form @submit.prevent="saveLeadForm">
    <b-card title="Lead Form" style="min-height: 500px" class="p-3">
      <b-row class="rounded-lg" style="gap: 25px 0">
        <!-- First Name -->
        <b-col cols="6">
          <label>Full Name</label>
          <b-form-input v-model="lead_form.full_name" required></b-form-input>
        </b-col>

        <!-- Email -->
        <b-col cols="6">
          <label>Email</label>
          <b-form-input type="email" v-model="lead_form.email"></b-form-input>
        </b-col>

        <!-- Mobile -->
        <b-col cols="6">
          <label>Mobile</label>
          <b-form-input v-model="lead_form.mobile"></b-form-input>
        </b-col>

        <!-- State -->
        <b-col cols="6">
          <label>State</label>
          <v-select label="name" :options="states" :reduce="(item) => item.state_id" v-model="lead_form.state_id"
            @input="lead_form.city_id = null"></v-select>
        </b-col>

        <!-- City -->
        <b-col cols="6">
          <label>City</label>
          <v-select :disabled="!lead_form.state_id ? true : false" label="name"
            :options="cities.filter((c) => c.state_id === lead_form.state_id)" :reduce="(item) => item.city_id"
            v-model="lead_form.city_id"></v-select>
        </b-col>

        <!-- Source -->
        <b-col cols="4">
          <label>Source</label>
          <v-select label="name" :options="sources" :reduce="(item) => item.ls_id"
            v-model="lead_form.source_id"></v-select>
        </b-col>

        <!-- Medium -->
        <b-col cols="4">
          <label>Medium</label>
          <v-select label="name" :options="mediums" :reduce="(item) => item.m_id"
            v-model="lead_form.medium_id"></v-select>
        </b-col>

        <!-- Campaign -->
        <b-col cols="4">
          <label>Campaign</label>
          <v-select label="name" :options="campaigns" :reduce="(item) => item.c_id"
            v-model="lead_form.campaign_id"></v-select>
        </b-col>

        <!-- Lead Stage -->
        <b-col cols="6">
          <label>Lead Stage</label>
          <v-select label="name" :options="stages" :reduce="(item) => item.s_id" v-model="lead_form.stage_id"
            @input="lead_form.sub_stage_id = null"></v-select>
        </b-col>

        <!-- Lead Sub Stage -->
        <b-col cols="6">
          <label>Lead Sub Stage</label>
          <v-select :disabled="!lead_form.stage_id ? true : false" label="name" :options="sub_stages.filter((ss) => ss.stage_id === lead_form.stage_id)
            " :reduce="(item) => item.ss_id" v-model="lead_form.sub_stage_id"></v-select>
        </b-col>

        <!-- Lead Category -->
        <!-- <b-col cols="12" v-if="user_type">
          <label>Lead Category</label>
          <v-select
            label="name"
            :options="categories.filter(lc => (lc.roles.map(r => r.role_key).includes(user_type)))"
            :reduce="(item) => item.lc_id"
            v-model="lead_form.category_id"
            @input="handleLeadCategoryFields"
          ></v-select>
        </b-col> -->

        <!-- Lead Category Fields -->
        <!-- <div class="border w-100 mx-1 rounded-lg p-2" v-if="lead_form.category_id">
          <h5 class="mb-2">Lead Category Fields</h5>

          <b-row style="gap: 20px 0">
            <b-col cols="4" v-for="field in field_values" :key="'field_' + field.lcf_id">
              <label>{{ field.name }}</label>
              <b-form-input v-model="field.value"></b-form-input>
            </b-col>
          </b-row>
        </div> -->

        <!-- Counselor -->
        <!-- <b-col cols="12">
          <label>Counselor</label>
          <v-select
            label="user_first_name"
            :options="users"
            :reduce="(item) => item.user_id"
            v-model="lead_form.user_id"
          ></v-select>
        </b-col> -->

        <!-- Remarks -->
        <b-col cols="12">
          <label>Remarks</label>
          <b-form-textarea v-model="lead_form.remark"></b-form-textarea>
        </b-col>

        <b-col cols="12" class="d-flex justify-content-end" style="gap: 10px">
          <b-button variant="danger">Cancel</b-button>
          <b-button variant="primary" type="submit">Submit</b-button>
        </b-col>
      </b-row>
    </b-card>
  </form>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormTextarea,
  BButton,
} from "bootstrap-vue";
import CommonServices from "@/apiServices/CommonServices";
import LmsServices from "@/apiServices/LmsServices";
import vSelect from "vue-select";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import store from "@/store";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    vSelect,
    BFormTextarea,
    BButton,
    ToastificationContentVue,
  },
  data() {
    return {
      lead_id: null,
      user_type: null,
      access_token: null,
      lms_api: null,
      is_loading: false,

      lead_form: {
        full_name: "",
        email: "",
        mobile: "",
        remark: "",
        state_id: null,
        city_id: null,
        source_id: null,
        medium_id: null,
        campaign_id: null,
        stage_id: null,
        sub_stage_id: null,
        category_id: 3,
        // user_id: null,
      },

      field_values: [],

      // Masters
      states: [],
      cities: [],
      stages: [],
      sub_stages: [],
      categories: [],
      category_fields: [],
      sources: [],
      mediums: [],
      campaigns: [],
      // users: [],
    };
  },
  methods: {
    async getLMSAccess() {
      try {
        this.is_loading = true;
        const res = await CommonServices.getLMSAccessToken();
        this.access_token = res.data.data;
        this.lms_api = LmsServices(this.access_token);

        this.getAllStates();
        this.getAllCities();
        this.getAllLeadStages();
        this.getAllLeadSubStages();
        this.getAllCategories();
        this.getAllCategoryFields();
        this.getAllLeadSources();
        this.getAllMediums();
        this.getAllCampaigns();
        // this.getAllUsers();

        if (this.lead_id) {
          this.getSingleLead();
        }
      } catch (error) {
        console.log({ error });
      }
    },

    async getSingleLead() {
      try {
        const res = await this.lms_api.getSingleLead(this.lead_id);

        if (!res.data.status) {
          console.log("here");
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: res.data.message,
              icon: "X",
              variant: "failure",
            },
          });
          this.$router.back();
          return;
        }

        const lead_data = res.data.data;

        this.lead_form = {
          full_name: lead_data.full_name,
          email: lead_data.email,
          mobile: lead_data.mobile,
          remark: lead_data.remark,
          state_id: lead_data.state_id,
          city_id: lead_data.city_id,
          source_id: lead_data.source_id,
          medium_id: lead_data.medium_id,
          campaign_id: lead_data.campaign_id,
          stage_id: lead_data.stage_id,
          sub_stage_id: lead_data.sub_stage_id,
          category_id: lead_data.category_id,
          // user_id: lead_data.user_id,
        };

        this.field_values = lead_data.field_values.map((fv) => ({
          lcf_id: fv.lcf_id,
          name: fv.field.name,
          value: fv.value,
        }));

        // console.log({ leadData });
      } catch (error) {
        console.log({ error });
      }
    },

    // Actions
    handleLeadCategoryFields() {
      if (!this.lead_form.category_id) {
        this.field_values = [];
        return;
      }

      this.field_values = this.category_fields
        .filter((lcf) => lcf.lc_id === this.lead_form.category_id)
        .map((fv) => ({
          name: fv.name,
          lcf_id: fv.lcf_id,
          value: "",
        }));
    },

    async saveLeadForm() {

      if (!this.lead_form.email && !this.lead_form.mobile) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Email or Mobile is Required",
            icon: "X",
            variant: "failure",
          },
        });

        return
      }

      const res = await this.lms_api.saveLead({
        lead_id: this.lead_id,
        ...this.lead_form,
        field_values: this.field_values,
      });

      if (!res.data.status) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Failed to save lead",
            icon: "X",
            variant: "failure",
          },
        });
      } else {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Lead Saved",
            icon: "Check",
            variant: "success",
          },
        });
        this.$router.back();
      }
    },

    // Masters
    async getAllStates() {
      try {
        const res = await this.lms_api.getAllStates();
        this.states = res.data.data;
      } catch (error) {
        console.log("Could Not Get States");
      }
    },
    async getAllCities() {
      try {
        const res = await this.lms_api.getAllCities();
        this.cities = res.data.data;
      } catch (error) {
        console.log("Could Not Get Cities");
      }
    },
    async getAllLeadStages() {
      try {
        const res = await this.lms_api.getAllLeadStages();
        this.stages = res.data.data;
      } catch (error) {
        console.log("Could Not Get Lead Stages");
      }
    },
    async getAllLeadSubStages() {
      try {
        const res = await this.lms_api.getAllLeadSubStages();
        this.sub_stages = res.data.data;
      } catch (error) {
        console.log("Could Not Get Lead Sub Stages");
      }
    },
    async getAllCategories() {
      try {
        const res = await this.lms_api.getAllCategories();
        this.categories = res.data.data;
      } catch (error) {
        console.log("Could Not Get Categories");
      }
    },
    async getAllCategoryFields() {
      try {
        const res = await this.lms_api.getAllCategoryFields();
        this.category_fields = res.data.data;
      } catch (error) {
        console.log("Could Not Get Categories");
      }
    },
    async getAllLeadSources() {
      try {
        const res = await this.lms_api.getAllLeadSources();
        this.sources = res.data.data;
      } catch (error) {
        console.log("Could Not Get Lead Sources");
      }
    },
    async getAllMediums() {
      try {
        const res = await this.lms_api.getAllMediums();
        this.mediums = res.data.data;
      } catch (error) {
        console.log("Could Not Get Mediums");
      }
    },
    async getAllCampaigns() {
      try {
        const res = await this.lms_api.getAllCampaigns();
        this.campaigns = res.data.data;
      } catch (error) {
        console.log("Could Not Get Campaigns");
      }
    },
    // async getAllUsers() {
    //   try {
    //     const res = await this.lms_api.getAllUsers();
    //     this.users = res.data.data;
    //   } catch (error) {
    //     console.log("Could Not Get Campaigns");
    //   }
    // },
  },

  computed: {
    user() {
      if (store.getters["user/getUserDetails"]) {
        return store.getters["user/getUserDetails"];
      } else {
        return null;
      }
    },
  },

  watch: {
    user(val) {
      console.log({ val });
      if (val.user_type) {
        this.user_type = val.user_type
      }
    },
  },

  beforeMount() {
    const { lead_id } = this.$route.query;

    this.lead_id = lead_id;
    this.getLMSAccess();
  },
};
</script>